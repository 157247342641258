import { generateCompanyURI } from './../sagas/helpers/generateCompanyURI'

export const endpoints = {
    auth: {
        login: '/login/',
        initializeSso: '/sso/dashboard/initiate/',
        ssoCallback: '/sso/dashboard/callback/',
        requestPasswordReset: '/request-password-reset/',
        resetPassword: '/reset-password/',
        requestVerificationCode: '/send-verification-code/',
        validateVerificationCode: '/verify-verification-code/',
    },
    home: {
        eventReminders: '/event-reminders/',
        solutions: {
            blogs: '/wordpress-blog-post/',
            tools: '/popular-tools/',
        },
        peopleSurveyedByRole: '/roles-surveyed/',
        diversityBreakdownByRole: '/diversity-breakdown-by-role/',
        inclusionInsights: '/inclusion-insights/',
        inclusionHeatmap: '/inclusion-scorecard/',
        diversityBreakdown: '/diversity-breakdown/',
        announcements: '/announcement/',
        pastSurveys: '/past-surveys/',
        inspirationalQuotes: '/inspirational-quote/',
        deiSummary: '/dei-summary/',
        riskBanner: '/risk-banner/',
        progressBanners: '/progress-banners/',
    },
    analyze: {
        companyFilters: '/company-filters/',
        diversity: {
            companyProfiles: '/diversity-company-profile-filter/',
            companyProfilesOptions: '/diversity-company-profile-filter/filters/',
            diversityData: '/diversity-data/',
            diversityDataOverTime: '/diversity-data-over-time/',
        },
        inclusion: {
            companyProfiles: '/inclusion-company-profile-filter/',
            companyProfilesOptions: '/inclusion-company-profile-filter/filters/',
            inclusionScore: '/inclusion-scores/',
            inclusionScoreOverTime: '/inclusion-scores-over-time/',
            freeTexts: '/inclusion-free-texts/',
            freeTextsFilters: '/free-text-filters/',
            bespokeFreeTexts: '/bespoke-free-texts/',
            heatmapPanel: '/inclusion-side-panel/',
        },
    },
    mySolutions: {
        reports: `/my-solutions${generateCompanyURI('/my-reports/')}`,
    },
    reporting: {
        reports: '/reports/',
        saveReport: '/save-report/',
        deleteReport: '/delete-report/',
    },
    programs: {
        programFilters: '/programs-filters/',
        allPrograms: '/programs/',
        saveProgram: '/save-program/',
        deleteProgram: '/delete-program/',
        changeProgramStatus: '/change-program-status/',
    },
    recommended: {
        saveProgram: '/save-program/',
        deleteProgram: '/delete-program/',
        changeProgramStatus: '/change-program-status/',
        recommendedInsights: '/insight-data/',
        goalOptions: '/solution-goal-card-options/',
        myGoals: '/my-goals/',
        deleteGoal: '/delete-my-goal/',
        recommendedPrograms: '/recommended-programs/',
    },
    tools: {
        allTools: '/tools/',
        toolsFilters: '/tools-filters/',
        saveTool: '/save-tool/',
        deleteTool: '/delete-tool/',
    },
    goals: {
        myGoals: '/my-goals/',
        savedSolutions: '/saved-solutions/',
        activeSolutions: '/active-solutions/',
        archivedSolutions: '/archived-solutions/',
        myTools: '/my-tools/',
        goalOptions: '/solution-goal-card-options/',
        deleteProgram: '/delete-program/',
        changeProgramStatus: '/change-program-status/',
        deleteGoal: '/delete-my-goal/',
        insightData: '/insight-data/',
        deleteTool: '/delete-tool/',
    },
}

import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, SelectableChip } from '@diversioteam/diversio-ds'
import moment from 'moment'

import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { useSendOrganizationEmail } from 'hooks/metadata/useSendOrganizationEmail'

import { EmailStatus } from './EmailStatus'
import { HeroExpiration } from './HeroExpiration'
import { createCancelRenewalEmail, createRenewalEmail } from './renewDialog.helpers'
import * as S from './renewDialog.styles'

export const CANCEL_REASONS = [
    'Found a Better Solution',
    'Budget Constraints',
    'Platform Not Meeting Expectations',
    'Too Complicated to Use',
    'Poor Customer Support',
    'No Longer a Priority',
    'Missing Key Features',
    'Other',
]

const DEFAULT_DEACTIVATION_DIALOG_MIN_DAYS_LEFT = 90
const CUSTOM_DEACTIVATION_DIALOG_MIN_DAYS_LEFT = Number(process.env.REACT_APP_DEACTIVATION_DIALOG_MIN_DAYS_LEFT)

export const DEACTIVATION_DIALOG_MIN_DAYS_LEFT =
    CUSTOM_DEACTIVATION_DIALOG_MIN_DAYS_LEFT || DEFAULT_DEACTIVATION_DIALOG_MIN_DAYS_LEFT

export const RenewDialog = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isCancelRenewalExpanded, setIsCancelRenewalExpanded] = useState(false)
    const [selectedCancelReasonsOptions, setSelectedCancelReasonsOptions] = useState<string[]>([])

    const mutationProcessRenewal = useSendOrganizationEmail()
    const mutationCancelRenewal = useSendOrganizationEmail()

    const { data } = useUserMetadata()

    const username = data?.username || '<username>'
    const company = data?.currentCompany?.name || '<company_name>'
    const companyUuid = data?.currentCompany?.uuid

    const storageKey = `renew-dialog-later-company-${companyUuid}`
    const dialogClickedLaterStatus = sessionStorage.getItem(storageKey)
    const hasClickedLater = dialogClickedLaterStatus === 'true'

    const contractExpiryDate = data?.currentCompany?.organization.contractExpiryDate
    const contractExpiryEmailInfo = data?.currentCompany?.organization.emailInfo || []
    const hasSubmittedEmail = contractExpiryEmailInfo.some(({ allowEmail }) => !allowEmail)

    const fullName = data?.fullName
    const hasFullName = !!fullName
    const fullNamePlaceholder = '<name>'
    const emailBodyFullName = hasFullName ? fullName : fullNamePlaceholder

    const toReplacePayload = !hasFullName ? { to_replace: { [fullNamePlaceholder]: 'name' } } : {}

    const now = moment()
    const dateEnd = moment(contractExpiryDate)
    const daysLeft = dateEnd.diff(now, 'days')

    useEffect(() => {
        const shouldOpenDialog = () => {
            if (daysLeft > DEACTIVATION_DIALOG_MIN_DAYS_LEFT) {
                return false
            }

            if (hasClickedLater) {
                return false
            }

            if (hasSubmittedEmail) {
                return false
            }

            return true
        }

        if (shouldOpenDialog()) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [daysLeft, hasClickedLater, hasSubmittedEmail])

    if (!daysLeft || daysLeft > DEACTIVATION_DIALOG_MIN_DAYS_LEFT) {
        return null
    }

    const handleCloseDialog = () => {
        sessionStorage.setItem(storageKey, 'true')

        setIsOpen(false)
    }

    const handleSubmitProcessRenewal = () => {
        const { subject, body } = createRenewalEmail({ username, company, daysLeft, emailBodyFullName })

        mutationProcessRenewal.mutate({
            email_type: 'contract_expiry',
            subject,
            body,
            ...toReplacePayload,
        })
    }

    const handleExpandCancelRenewal = () => {
        setIsCancelRenewalExpanded(true)
    }

    const handleSubmitCancelRenewal = () => {
        const { subject, body } = createCancelRenewalEmail({
            username,
            company,
            emailBodyFullName,
            selectedCancelReasonsOptions,
        })

        mutationCancelRenewal.mutate({
            email_type: 'deactivate_subscription',
            subject,
            body,
            ...toReplacePayload,
        })
    }

    const handleSelectCancelReasonOption = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target

        if (checked) {
            setSelectedCancelReasonsOptions((prevState) => [...prevState, value])
        } else {
            setSelectedCancelReasonsOptions((prevState) => prevState.filter((option) => option !== value))
        }
    }

    const isRenewContentVisible =
        (mutationProcessRenewal.isIdle || mutationProcessRenewal.isLoading) &&
        (mutationCancelRenewal.isIdle || mutationCancelRenewal.isLoading)

    return (
        <S.RenewDialogWrapper open={isOpen} onClose={handleCloseDialog} hideBackdrop={false}>
            {mutationProcessRenewal.isSuccess && <EmailStatus type="success" onCloseDialog={handleCloseDialog} />}

            {mutationCancelRenewal.isSuccess && <EmailStatus type="warning" onCloseDialog={handleCloseDialog} />}

            {(mutationProcessRenewal.isError || mutationCancelRenewal.isError) && (
                <EmailStatus type="error" onCloseDialog={handleCloseDialog} />
            )}

            {isRenewContentVisible && (
                <>
                    <HeroExpiration daysLeft={daysLeft} />

                    <S.Alert>
                        Your Dashboard access will be terminated if you do not renew your subscription by{' '}
                        <span>{dateEnd.format('Do MMMM')}</span>. Please proceed with the following two options:
                    </S.Alert>

                    <S.Section>
                        <S.SectionText>
                            <S.SectionText>
                                <S.SectionTitle>Looking to extend with Diversio?</S.SectionTitle>

                                <S.SectionDescription>
                                    Connect with your Account Executive to renew your access
                                </S.SectionDescription>
                            </S.SectionText>
                        </S.SectionText>

                        <Button onClick={handleSubmitProcessRenewal} loading={mutationProcessRenewal.isLoading}>
                            Process Renewal
                        </Button>
                    </S.Section>

                    {!isCancelRenewalExpanded && (
                        <S.Section>
                            <S.SectionText>
                                <S.SectionTitle>No longer need Diversio?</S.SectionTitle>

                                <S.SectionDescription>
                                    Notify your Account Executive (AE) about canceling your services
                                </S.SectionDescription>
                            </S.SectionText>

                            <Button onClick={handleExpandCancelRenewal} color="secondary">
                                Cancel Renewal
                            </Button>
                        </S.Section>
                    )}

                    {isCancelRenewalExpanded && (
                        <S.SectionColumn>
                            <S.SectionTitle>What is your reason for cancelling?</S.SectionTitle>

                            <S.SectionDescription>Select all that apply</S.SectionDescription>

                            <S.List>
                                {CANCEL_REASONS.map((option) => {
                                    const isOptionSelected = selectedCancelReasonsOptions.includes(option)

                                    return (
                                        <S.ListOption key={option}>
                                            <SelectableChip
                                                variant="outlined"
                                                focused={isOptionSelected}
                                                selected={isOptionSelected}
                                                name={option}
                                                label={option}
                                                value={option}
                                                onChange={handleSelectCancelReasonOption}
                                            />
                                        </S.ListOption>
                                    )
                                })}
                            </S.List>

                            <Button
                                onClick={handleSubmitCancelRenewal}
                                loading={mutationCancelRenewal.isLoading}
                                disabled={selectedCancelReasonsOptions.length === 0}
                            >
                                Submit
                            </Button>
                        </S.SectionColumn>
                    )}
                </>
            )}
        </S.RenewDialogWrapper>
    )
}

import React, { useMemo, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { parseYearQuarter } from 'utils'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { FiltersContextController } from 'context/filters/filtersContextController/FiltersContextController'
import { useViewTracking } from 'hooks/useViewTracking'
import { useVersion } from 'hooks/useVersion'
import { useUserInformation } from 'hooks/useUserInformation'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'

import { NoInsights } from './../NoInsights'
import { FilterPane } from './FilterPane'
import { Data } from './Data'
import { DiversityParams } from './diversity.types'
import * as S from './diversity.styles'

type SelectedFilter = string | SelectedFilters

export type SelectedFilters =
    | undefined
    | {
          [key: string]: SelectedFilter
      }

const DiversityWithoutEB = () => {
    useViewTracking('Analyze v2 - Diversity')

    const versionsContext = useVersion()
    const { enableDebugMode } = useUserInformation()

    const [showProfilesPage, setShowProfilesPage] = useState(false)

    const { tab } = useParams<DiversityParams>()
    const [selectedTab, setSelectedTab] = useState<string>(tab)
    const history = useHistory()

    const { survey } = useSurveys()

    const params = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const queryGetCompanyFilters = useGetCompanyFilters(params)

    const onTabChange = (to: string) => {
        setSelectedTab(to)

        if (tab !== to) {
            return history.replace(`/analyze2/diversity/${to}${location.search}`)
        }
    }

    const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2

    const { hasBespokeDiversityQuestions, hasDiversityQuestions, hasEngagementDiversityQuestions } =
        queryGetCompanyFilters.data || {}

    const hasNoData = !hasBespokeDiversityQuestions && !hasDiversityQuestions && !hasEngagementDiversityQuestions

    if (!isV2Enabled && !enableDebugMode) {
        return <Redirect to={generateCompanyURI('/analyze/diversity')} />
    }

    if (queryGetCompanyFilters.isFetched && hasNoData) {
        return (
            <NoInsights
                details={[
                    {
                        title: 'Interested in collecting Diversity data?',
                        description: (
                            <>
                                Contact your CSM or send a message to{' '}
                                <a href="mailto:client-success@diversio.com">client-success@diversio.com</a>
                            </>
                        ),
                    },
                    {
                        title: 'Why don’t I see Diversity results? ',
                        description:
                            'Double check the time period at the top left of screen to ensure diversity data was collected.',
                    },
                    {
                        title: 'What insights does Diversity data provide?',
                        description:
                            'Representation breakdowns to compare your Dominant group to Non-Dominant group, multiple industry & national benchmarks, and employee headcount, distribution, and intersectionality.',
                    },
                ]}
            />
        )
    }

    return (
        <FiltersContextController>
            <S.Diversity>
                <S.Content>
                    <S.LeftBox>
                        <FilterPane
                            handleShowProfilesPage={(isProfilesVisible) => setShowProfilesPage(isProfilesVisible)}
                            selectedTab={selectedTab}
                            handleOnTabChange={onTabChange}
                        />
                    </S.LeftBox>
                    <S.RightBox>
                        <Data showProfilesPage={showProfilesPage} selectedTab={tab} />
                    </S.RightBox>
                </S.Content>
            </S.Diversity>
        </FiltersContextController>
    )
}

export const Diversity = withErrorBoundary(DiversityWithoutEB, {})

import React, { useState, useEffect } from 'react';
import Progress from "./Progress";
import OpenText from "./QuestionTypes/OpenText";
import SingleChoice from "./QuestionTypes/SingleChoice";
import Rating from "./QuestionTypes/Rating";
import {Accordion, Card, Container} from "react-bootstrap";
import classNames from "classnames";
import {Trans, withTranslation} from 'react-i18next';
import _ from 'underscore';
import Intro from "./QuestionTypes/Intro";
import GlobalSurveyErrors from "./GlobalSurveyErrors";
import Joyride from 'react-joyride';
import TAXONOMIES from "../../utils/taxonomies";
import mixpanel from "mixpanel-browser";

const Create = ({
    selectedSurvey = {},
    updateSurveyQuestions,
    updateSurveyIntro,
    isSaving,
    surveyErrors,
    handleCreateBack,
    handleCreateNext,
    handleReset,
    draftSurveys,
    company,
    selfServe,
    preview,
    t
}) => {
    const [tab, setTab] = useState("en");
    const [showJoyride, setShowJoyride] = React.useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!preview && localStorage.getItem("joyride__create--completed") !== "1") {
                setShowJoyride(true);
            }
        }, 2000);

        return () => {
            setShowJoyride(false);
        }
    }, [])

    const [steps, setSteps] = React.useState([
        {
            target: '.datatable-content .accordion:nth-child(2)',
            content: 'We have created a starter template that you can use to create your own survey. Feel free to make changes to the options.',
            disableBeacon: true,
        },
        {
            target: '.next-btn',
            content: 'Once you have customized your questions, click here to create your survey!',
        },
    ])

    const selectTab = lang => {
        setTab(lang);
    };

    useEffect(() => {
        const dom = document.querySelector('.survey-create .show');
        dom && dom.parentNode.querySelector(".card-header").classList.add("open");
    }, []);

    useEffect(() => {
        const { surveys } = selectedSurvey;
        if (!surveys) { return; }
        let allLangs = surveys.map(survey => survey.supported_language);
        if (!allLangs.includes("en")) {
            selectTab(allLangs[0]);
        }
    }, [selectedSurvey.surveys]);

    if (_.isEmpty(selectedSurvey)) {
        return null;
    }
    const { surveys } = selectedSurvey;

    const _rotateToggle = (e) => {
        const hasClass = e.target.classList.contains("open");
        document.querySelectorAll('.survey-create .collapse').forEach(el => el.parentNode.querySelector(".card-header").classList.remove("open"));
        e.target.classList[hasClass ? "remove" : "add"]("open");
    };

    const _handleQuestionChange = (question) => {
        updateSurveyQuestions(question);
    };

    const _handleIntroChange = (intro, surveyId) => {
        updateSurveyIntro({ intro, surveyId });
        mixpanel.track(TAXONOMIES.EDIT_INTRO_SECTION)
    };

    const _renderSurveyTableContent = (survey) => {
        if (!survey || !survey.questions) {
            return null;
        }

        const surveyIdx = _.findIndex(surveys, survey => survey.supported_language === tab);
        const errors = surveyErrors[surveyIdx];
        survey.questions = survey.questions.map((question, id) => {
            return {
                ...question,
                qnum: id + 1,
                error: _.maybe(errors, "questions", id)
            }
        });
        const groupedQuestions = _.groupBy(survey.questions, "page_category");
        const pageCategoryDescriptions = _.where(survey.page_category_descriptions, {"supported_language": survey.supported_language});

        return <tbody>
            {
                showJoyride ? <Joyride steps={steps}
                                       continuous={true}
                                       locale={{ last: 'Close' }}
                                       styles={{
                                           options:{
                                               primaryColor: '#6824ec'
                                           }
                                       }}
                                       scrollOffset={250}
                                       callback={data => {
                                           const { status } = data;
                                           if (status === "skipped") {
                                               localStorage.setItem("joyride__create--completed", "1");
                                               mixpanel.track(TAXONOMIES.CREATE_ONBOARDING_SKIPPED);
                                           }
                                           if (status === "finished") {
                                               localStorage.setItem("joyride__create--completed", "1");
                                               mixpanel.track(TAXONOMIES.CREATE_ONBOARDING_COMPLETE);
                                           }
                                       }}
                                       disableScrollParentFix
                /> : null
            }
            <tr className={"datatable-content"}>
                <GlobalSurveyErrors errors={errors}/>
                <Accordion defaultActiveKey="1" onClick={_rotateToggle}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            { t("SURVEY.INTRODUCTION_HEADING") }
                            <img className={"icon icon-caret"}/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className={"intro-content"}>
                                {/*Survey type 'D' means that the survey being deployed only has diversity questions (aka no inclusion questions)*/}
                                <Intro
                                    content={selectedSurvey.download_responses ? survey.alt_intro : survey.intro}
                                    company={company}
                                    handleChange={data => {
                                        // Trigger only if the user has made edit to the original intro. Original intro itself varies
                                        // based on whether the user chooses to download responses or not in the setup page. .
                                        if (data.intro !== (selectedSurvey.download_responses ? survey.alt_intro : survey.intro)) {
                                            _handleIntroChange(data.intro, survey.id)
                                        }
                                    }} preview={preview} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                {
                    Object.keys(groupedQuestions).map((groupKey, idx) => {
                        const questions = groupedQuestions[groupKey];
                        const categoryDescription = _.findWhere(pageCategoryDescriptions, { page_category: groupKey });
                        return <Accordion defaultActiveKey={idx+1} onClick={_rotateToggle}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={idx+1}>
                                    {t(`SURVEY.${groupKey.toUpperCase()}`)}
                                    <img className={"icon icon-caret"}/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={idx+1}>
                                    <Card.Body>
                                        <>
                                        {
                                            categoryDescription?.description && <>
                                                <div className={"survey-create__content-container__description"}>{categoryDescription?.description}</div>
                                                <hr/>
                                            </>
                                        }
                                        <ul className={"survey-create__content-container__questions"}>
                                            {
                                                questions.map((question, id) => {
                                                    switch(question.field_type) {
                                                        case 'open_text':
                                                            return <OpenText key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange} preview={preview}/>;
                                                        case 'single_choice':
                                                            return <SingleChoice multiSelectType={"radio"} key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange} preview={preview}/>;
                                                        case 'multiple_choice':
                                                            return <SingleChoice multiSelect={"checkbox"} key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange} preview={preview}/>;
                                                        case 'single_choice_with_open_text':
                                                            return <SingleChoice multiSelectType={"radio"} key={id} openText error={question.error} question={question} company={company} handleChange={_handleQuestionChange} preview={preview}/>;
                                                        case 'multiple_choice_with_open_text':
                                                            return <SingleChoice multiSelectType={"checkbox"} key={id} openText error={question.error} question={question} company={company} handleChange={_handleQuestionChange} preview={preview}/>;
                                                        case 'rating':
                                                            return <Rating key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange} preview={preview}/>;
                                                    }
                                                })
                                            }
                                        </ul>
                                        </>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    })
                }
            </tr>
        </tbody>
    };

    const _renderSurveyTable = (surveys, preview) => {
        return <table className={"datatable"}>
            <thead>
            {
                surveys.length > 1 && <>
                    <tr className={"datatable-filters"}>
                        {
                            surveys.map(survey => <th onClick={() => selectTab(survey.supported_language)}
                                                      className={classNames({"selected": tab === survey.supported_language})}>
                                { t(`LANGUAGES.${survey.supported_language.toUpperCase()}`) }
                            </th>)
                        }
                    </tr>
                    <tr className={"datatable-border"}></tr>
                </>
            }

            </thead>
            {
                _renderSurveyTableContent(surveys.filter(survey => survey.supported_language === tab)[0])
            }
        </table>
    }

    return (
        <div className={"survey-create"}>
            {
                !preview
                && <Progress selected={"create"} supportedLanguages={_.pluck(surveys, "supported_language")} errors={surveyErrors} handleBack={handleCreateBack} handleNext={handleCreateNext} handleReset={handleReset} draftSurveys={draftSurveys} isSaving={isSaving} selfServe={selfServe}/>
            }
            <Container className={classNames("survey-create__content-container", { preview: preview })}>
                {
                    !preview
                        && <div className={"header"}>
                            <div className={"header-content-container"}>
                                <h5> {company} {selectedSurvey.survey_type === "D" ? "Diversity" : "Diversity & Inclusion"} Survey</h5>
                                <p>This is a template survey tailored to your country, language and type of respondents. Please read through carefully and customize the fields to be relevant to your company. </p>
                            </div>
                            <div className={"note-container"}>
                                <h5>NOTE: </h5>
                                <ul>
                                    <li>Additional questions have a standard fee</li>
                                    <li>Any modified questions will need to be approved by a Diversio team member</li>
                                </ul>
                            </div>
                        </div>
                }
                {
                    _.isEmpty(surveys)
                        ? <p>No questions to display</p>
                        : _renderSurveyTable(surveys, preview)
                }
            </Container>
        </div>
    );
};

export default withTranslation()(Create);

/*global io*/
import { captureException } from '@sentry/react';
import { getCompanyUUID } from '../utils/getCompanyUUID/getCompanyUUID';
import {doSignout} from './auth.js'
import { generateCompanyURI } from './helpers/generateCompanyURI';

export const serverURL = process.env.REACT_APP_DASHBOARD_API_ROOT;
export const serverURLV2 = process.env.REACT_APP_DASHBOARD_API_ROOT_V2;

export function generatorRunner(gen){
    const iterator = gen();
    const run = function(x){
        const result = iterator.next();
        if(result.done){
            return result.value
        } else {
            return Promise.resolve(result.value).then(run)
        }
    };
    return run
}

export async function parseToJson(data) {
    if (!data.ok) {
        return Promise.reject(data)
    }

    if (data.status === 204) {
        return {}
    }
    
    return data.json()
}

/**
 * parseToJsonPromise
 * Returns JSON result for the fetch response
 * e.g. parseToJsonPromise(data) => { ...values }
 *
 **/

function parseToJsonPromise(data) {
    return new Promise((res, rej) => {
       
        if(data.status === 204) {
            let response = {
                successful: data.status.toString().indexOf("2") === 0,
                code: data.status
            };
            return res(response)
        }
        
        data.json().then(object => {
            let response = {
                successful: data.status.toString().indexOf("2") === 0,
                data: object,
                code: data.status
            };

            return res(response);
        }).catch(object=> {
            return res(object)
        })
    });
}

function parseToBlobPromise(data) {
    return new Promise((res, rej) => {
        data.blob().then(blob => {
            const fileNameWhole = data.headers.get("Content-Disposition");
            const fileName = fileNameWhole?.split("=")?.[1] || "Heatmap-Overview.xlsx";
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute("download", fileName);
            a.click();
            return res(blob);
        }).catch(object=> {
            return res(object)
        })
    });
}

const handle401 = function (response) {
    if (response.code === 401) {
        generatorRunner(doSignout)()
    }

    return response
};


export const apiGenerator = (resourceURL, parser) => {
    return (uri, method, payload, type) => {
        const accessToken = localStorage.getItem("auth_access_token");

        let headers = {
            'Accept': 'application/json',
            'CompanyUUID': getCompanyUUID()
        };

        if (accessToken) {
            headers['Authorization'] = `JWT ${accessToken}`;
        }

        if (type !== "file") {
            headers["Content-Type"] = "application/json";
            payload = JSON.stringify(payload);
        }


        const req = fetch(`${resourceURL}${uri}`, {
            method,
            body: payload,
            headers
        });

        
        if (type === "file") {
            return req.then(parseToBlobPromise).then(handle401).catch(error => {
                captureException(error);
            })
        } else {        
            return req.then(parser || parseToJsonPromise).then(handle401)
        }
    };
}

export const socketGenerator = (url, method, payload) => {
    return new Promise((res, rej) => {
        io.socket.request({
            url: url,
            method: method,
            headers: io.sails.headers,
            data: payload
        }, (resData, jwRes) => {
            const successful = jwRes.statusCode === 200;
            res({data: resData, successful});
        })
    })
};

export const unauthorizedApiGenerator = (resourceURL) => {
    return (uri, method, payload, type) => {
        let headers = {
            'Accept': 'application/json'
        };

        if (type !== "file") {
            headers["Content-Type"] = "application/json";
            payload = JSON.stringify(payload);
        }

        return fetch(`${resourceURL}${uri}`, {
            method,
            body: payload,
            headers
        }).then(parseToJsonPromise).catch(error => {
            throw error
        });
    };
}

export const networkAdapter = (generator) => {
    return {
        POST: (url, payload, type) => generator(url, 'POST', payload, type),
        PUT: (url, payload) => generator(url, 'PUT', payload),
        DELETE: (url) => generator(url, 'DELETE'),
        GET: (url, type) => generator(url, 'GET', undefined, type),
        PATCH: (url, payload) => generator(url, 'PATCH', payload)
    }
};

export const generateAPI = (prefix) => {
    return (url = '', isCompanySpecific = true) => {
        return `/${prefix}${generateCompanyURI(url, isCompanySpecific)}`
    }
}

export const homeAPI = generateAPI('home')
export const analyzeAPI = generateAPI('analyze')
export const programAPI = generateAPI('programs')
export const reportingAPI = generateAPI('reporting')
export const toolsAPI = generateAPI('tools')
export const goalsAPI = generateAPI('goals')
export const mySolutionsAPI = generateAPI('my-solutions')
export const recommendedAPI = generateAPI('recommended')
export const userMetadataAPI = generateAPI('usermetadata')

export const getCompanyRelativePathName = (uri) => {
    const uriFragments = uri.split("/")
    if (uriFragments[1] === 'company') {
        return `/${uriFragments.splice(3).join('/')}`
    } else {
        return `${uriFragments.join('/')}`
    }
  }
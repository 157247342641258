/*global io*/
import { endpoints } from "./../api/endpoints";
import { apiGenerator, programAPI, networkAdapter, parseToJson, serverURL, serverURLV2, socketGenerator, unauthorizedApiGenerator, analyzeAPI, toolsAPI, homeAPI, trainingsAPI, reportingAPI, recommendedAPI, userMetadataAPI, mySolutionsAPI } from "./util";
import { generateCompanyURI } from "./helpers/generateCompanyURI";

/**
 *
 * @type {{POST: ((p1?:*, p2?:*)), PUT: ((p1?:*, p2?:*)), DELETE: ((p1?:*)), GET: ((p1?:*))}}
 * @private
 */

// TODO: @sahanarula goal is to rename the NON_SANITIZED with _requestAPI
// once the legacy code is completely detached. Earlier we used to send the 
// data within an object which had other information like network errors and status
// codes. Since all of this will now be handled with react-query, its good to keep 
// the network calls or promises virgin
const _requestAPI_NON_SANITIZED = networkAdapter(apiGenerator(serverURL, parseToJson))
const _requestSocket = networkAdapter(socketGenerator);
const _requestAPI = networkAdapter(apiGenerator(serverURL));
const _requestAPI2 = networkAdapter(apiGenerator(serverURLV2, parseToJson));
const _requestUnauthorizedAPI = networkAdapter(unauthorizedApiGenerator(serverURL));

// Question Network Util
export const getAllQuestions = () => _requestSocket.GET("/question/");

// Calculator Network Util
export const submitResponse = (payload) => _requestSocket.POST("/response/", payload);
export const saveEmail = (payload) => _requestSocket.POST("/saveEmail/", payload);
export const loadEmailResponse = (email) => _requestSocket.GET(`/pdf/?email=${email}`);

// Analyze Network Util
export const loadDiversityBreakdownData = (queryString) => _requestAPI.GET(`/analyze_diversity_breakdown/?${queryString}`);
export const loadInclusionMetricsData = (queryString) => _requestAPI.GET(`/analyze_inclusion_metrics/?${queryString}`);
export const loadInclusionMetricsDataBespoke = (queryString) => _requestAPI.GET(`/analyze_inclusion_metrics/?${queryString}`);
export const loadInclusionMetricsExcel = (queryString) => _requestAPI.GET(`/analyze_inclusion_metrics/?${queryString}`, "file");
export const loadNonOverviewMetricsData = (queryString) => _requestAPI.GET(`/non_overview_metrics/?${queryString}`);
export const loadNonOverviewMetricsDataBespoke = (queryString) => _requestAPI.GET(`/non_overview_metrics/?${queryString}`);
export const loadNonOverviewMetricsExcel = (queryString) => _requestAPI.GET(`/non_overview_metrics/?${queryString}`, "file");
export const loadInclusionBreakdownData = (queryString) => _requestAPI.GET("/analyze_inclusion_breakdown/?" + queryString);
export const loadCompanyFilters = (queryString) => _requestAPI.GET(`/company_filters/?` + queryString);
export const loadInclusionTrackerData = queryString => _requestAPI.GET(`/inclusion_tracker/?${queryString}`);
export const loadDiversityTrackerData = queryString => _requestAPI.GET(`/diversity_tracker/?${queryString}`);
export const loadDiversityBreakdownByRoleData = (queryString) => _requestAPI.GET(`/diversity_breakdown_by_role/?${queryString}`);
export const loadFreeText = () => _requestAPI.GET(`/free_texts/`);

// Auth Network Util
export const handleLogin = (payload) => _requestAPI.POST("/login/", payload);
export const handleRegistration = (payload) => _requestUnauthorizedAPI.POST("/register/", payload);
export const handleSelfServeRegistration = (payload) => _requestUnauthorizedAPI.POST("/self_serve_register/", payload);
export const refreshToken = (payload) => _requestAPI.POST("/token-refresh/", payload);
export const requestVerificationCode = (payload) => _requestAPI.POST("/send-verification-code/", payload);
export const validateVerificationCode = (payload) => _requestAPI.POST("/verify-verification-code/", payload);
export const fetchExistingBackupCodes = (payload) => _requestAPI.POST("/backup-codes/existing/", payload);
export const fetchNewBackupCodes = (payload) => _requestAPI.POST("/backup-codes/new/", payload);
export const initializeSSO = (payload) => _requestAPI.POST("/sso/dashboard/initiate/", payload);
export const ssoCallback = (payload) => _requestAPI.POST("/sso/dashboard/callback/", payload);
export const signout = () => { /* Not required as JWTs are stateless anyway, just invalidate the token from the frontend */
};
export const setActiveDashboard = (payload) => _requestAPI.POST("/set_active_dashboard/", payload)
// Home Network Util
export const loadExecSummaryData = (token) => _requestAPI.GET(`/home_exec_summary/`);
export const loadPainpointData = (token) => _requestAPI.GET(`/home_painpoint/`);
export const loadDiversityCompositionData = (token) => _requestAPI.GET(`/home_diversity_composition/`);
export const loadMetricOverviewData = (token) => _requestAPI.GET(`/home_metric_overview/`);
export const loadFocusAreaData = (token) => _requestAPI.GET(`/home_focus_area/`);
export const loadInclusionScoreData = (token) => _requestAPI.GET(`/home_inclusion_score/`);
export const informPurchase = () => _requestAPI.GET('/inform_purchase/');
export const loadEmployeeOverviewData = () => _requestAPI.GET(`/home_employee_overview/`);

// Admin Network Util
export const uploadCSV = (payload) => _requestAPI.POST("/csv_import/", payload, "file");

// Connect -> Profile
export const loadProfile = () => _requestAPI.GET('/connect/profile/');
export const updateProfile = (payload) => _requestAPI.PUT('/connect/profile/', payload);

// Bespoke
export const loadBespokeDemographics = (queryString) => _requestAPI.GET(`/bespoke-demographics/?${queryString}`);

// Password
export const changePassword = (payload) => _requestAPI.POST('/change-password/', payload);
export const requestResetPassword = (payload) => _requestUnauthorizedAPI.POST('/request-password-reset/', payload);
export const resetPassword = (payload) => _requestUnauthorizedAPI.POST('/reset-password/', payload);

// Communications
export const loadCommunicationsTokens = () => _requestAPI.GET('/voyager/user/');
export const updateCommunicationsTokens = (payload) => _requestAPI.PUT('/voyager/user/', payload);
export const deleteCommunicationsTokens = (payload) => _requestAPI.DELETE('/voyager/user/', payload);
export const loadCommunicationsSlackAnalytics = (payload) => _requestAPI.POST(`/voyager/slack/`, payload);
export const loadCommunicationsGithubAnalytics = (payload) => _requestAPI.POST(`/voyager/github/`, payload);

// Survey Automation
export const saveSurveyInfo = payload => _requestAPI.POST('/titan/survey/create/', payload);
export const loadCoreSurveys = queryString => _requestAPI.GET(`/titan/survey/core-questions/?${queryString}`);
export const saveSurveys = payload => _requestAPI.POST(`/titan/survey/save-surveys/`, payload);
export const editSurveys = payload => _requestAPI.POST(`/titan/survey/edit-surveys/`, payload);
export const draftSurveys = payload => _requestAPI.POST(`/titan/survey/draft-surveys/`, payload);
export const loadAllSurveys = payload => _requestAPI.GET(`/titan/survey/get-all-surveys/`);
export const refreshResponseCount = (payload) => _requestAPI.GET(`/titan/survey/get-response-count/${payload.surveyinfo_id}/`);
export const resetSurveyInfo = (surveyinfo_id) => _requestAPI.POST(`/titan/survey/cancel-surveys/${surveyinfo_id}/`);

// ILN Dashboard
export const loadILNinitiatives = () => _requestAPI.GET(`/ilndashboard/initiative/`)
export const loadILNmembers = () => _requestAPI.GET(`/ilndashboard/users/`)
export const loadILNmilestones = () => _requestAPI.GET(`/ilndashboard/milestone/`)
export const loadILNinvestmentRegions = () => _requestAPI.GET(`/ilndashboard/investment-region/`)
export const loadILNAUMBreakdown = () => _requestAPI.GET(`/ilndashboard/aum-breakdown/`)
export const loadILNnews = () => _requestAPI.GET(`/ilndashboard/iln-news/`)
export const loadILNCalendar = () => _requestAPI.GET(`/ilndashboard/iln-calendar/`)


// Solution Feature
// TODO:
// -  Replace /${payload.id}/ with ${payload.id}
// - clean up unused api calls

// all programs
export const loadSolutionAllPrograms = (payload) => _requestAPI.GET(`/solutions/solution-programs/${payload.id}/programs/?${payload.query}`)
export const loadSolutionAllProgramsFilters = (payload) => _requestAPI.GET(`/solutions/solution-programs/${payload.id}/programs_filters/`)

// goals
export const loadSolutionGoalsOptions = () => _requestAPI.GET(`/solutions/solution-goal-card-options/`)
export const loadSolutionGoalsInsightData = (payload) => _requestAPI.GET(`/solutions/solution-goals/${payload.id}/insight_data/`)
export const loadSolutionGoalsCreateGoal = (payload) => _requestAPI.POST('/solutions/solution-goals/', payload)
export const loadSolutionGoalsRecommendedPrograms = (payload) => _requestAPI.GET(`/solutions/solution-goals/${payload.id}/recommended_programs/?${payload.query}`)
export const loadSolutionGoalDelete = (payload) => _requestAPI.DELETE(`/solutions/solution-goals/${payload}/`)
// my solutions
export const loadSolutionMyGoals = (payload) => _requestAPI.GET(`/solutions/solution-my-solutions/${payload.id}/my_goals/`)
export const loadSolutionMyPrograms = (payload) => _requestAPI.GET(`/solutions/solution-my-solutions/${payload.id}/my_programs/`)
export const loadSolutionMyTools = (payload) => _requestAPI.GET(`/solutions/solution-my-solutions/${payload.id}/my_tools/`)
export const getMyReports = () => _requestAPI2.GET(`/my-solutions${generateCompanyURI('/my-reports/')}`)

export const loadSolutionMyAddProgram = (payload) => _requestAPI.POST(`/solutions/solution-my-solutions/`, payload)

export const loadSolutionMySolutionFilters = (payload) => _requestAPI.GET(`/solutions/solution-my-solutions/${payload.id}/my_solution_filters/`)
export const loadSolutionMySolutionUpdateGoal = (payload) => _requestAPI.PATCH(`/solutions/solution-goals/${payload.id}/`, payload.data)

// my solutions archive
export const loadSolutionMySolutionsArchive = (payload) => _requestAPI.GET(`/solutions/solution-my-solutions/${payload.id}/archived_solutions/?${payload.query}`)

// recommended
export const loadSolutionRecommendedInsights = (payload) => _requestAPI.GET(`/solutions/solution-recommended/${payload.id}/recommended_insights/`)
export const loadSolutionRecommendedPrograms = (payload) => _requestAPI.GET(`/solutions/solution-goals/${payload.id}/recommended_programs/?${payload.query}`)
export const loadSolutionRecommendedCreateGoal = (payload) => _requestAPI.POST('/solutions/solution-goals/', payload)
export const loadSolutionRecommendedGoalsOptions = (payload) => _requestAPI.GET(`/solutions/solution-goals/${payload.id}/goal_card_options/`)
export const loadSolutionRecommendedClientGoals = (payload) => _requestAPI.GET(`/solutions/solution-goals/${payload.id}/options/`)

// tools
export const loadSolutionEvents = (payload) => _requestAPI.GET(`/solutions/solution-tools/${payload.id}/events/`);
export const loadSolutionTools = (payload) => _requestAPI.GET(`/solutions/solution-tools/${payload.id}/tools/?${payload.query}`);
export const loadSolutionTrainings = (payload) => _requestAPI.GET(`/solutions/solution-trainings/${payload.id}/trainings/?${payload.query}`);
export const loadSolutionToolsFilters = (payload) => _requestAPI.GET(`/solutions/solution-tools/${payload.id}/tools_filters/`);

export const loadSolutionSaveTool = (payload) => _requestAPI.POST('/solutions/solution-company-tools/', payload)
export const loadSolutionDeleteTool = (payload) => _requestAPI.DELETE(`/solutions/solution-company-tools/${payload}/`)

export const loadSolutionDeleteCompanySolution = (payload) => _requestAPI.DELETE(`/solutions/solution-my-solutions/${payload}/`)
export const loadSolutionPatch = (payload) => _requestAPI.PATCH(`/solutions/solution-my-solutions/${payload.solution}/`, payload.fields)


// New Home APIs, not used by sagas but are loaded by hooks instead
export const loadInspirationalQuotes = () => _requestAPI2.GET(homeAPI(endpoints.home.inspirationalQuotes, false))
export const loadPastSurveys = () => _requestAPI2.GET(homeAPI(endpoints.home.pastSurveys))
export const loadAnnouncements = () => _requestAPI2.GET(homeAPI(endpoints.home.announcements))
export const getPeopleSurveyedByRole = (queryString) => _requestAPI2.GET(homeAPI(`${endpoints.home.peopleSurveyedByRole}?${queryString}`))
export const getBlogs = () => _requestAPI2.GET(homeAPI(endpoints.home.solutions.blogs, false))
export const getTools = () => _requestAPI2.GET(homeAPI(endpoints.home.solutions.tools))
export const getDiversityBreakdownByRole = (queryString) => _requestAPI2.GET(`${homeAPI(endpoints.home.diversityBreakdownByRole)}?${queryString}`)
export const getInclusionInsights = (queryString) => _requestAPI2.GET(`${homeAPI(endpoints.home.inclusionInsights)}?${queryString}`)
export const getInclusionHeatmap = (queryString) => _requestAPI2.GET(`${homeAPI(endpoints.home.inclusionHeatmap)}?${queryString}`)
export const getDiversityBreakdown = (queryString) => _requestAPI2.GET(`${homeAPI(endpoints.home.diversityBreakdown)}?${queryString}`)

// New Analyze APIs, not used by sagas but are loaded by hooks instead
export const loadFilters = () => _requestAPI2.GET(generateCompanyURI(endpoints.analyze.companyFilters))
export const loadDEISummary = () => _requestAPI2.GET(homeAPI(endpoints.home.deiSummary))
export const loadRiskBanner = (queryString) => _requestAPI2.GET(`${homeAPI(endpoints.home.riskBanner)}?${queryString}`)
export const loadProgressBanner = () => _requestAPI2.GET(homeAPI(endpoints.home.progressBanners))
export const getEventReminders = () => _requestAPI2.GET(homeAPI(endpoints.home.eventReminders, false))

// New Analyze APIs, not used by sagas but are loaded by hooks instead
// Filters
export const getCompanyFilters = (queryString) => _requestAPI2.GET(`${generateCompanyURI(endpoints.analyze.companyFilters)}?${queryString}`)

// Diversity

// Profiles
export const getDiversityCompanyProfiles = (queryString,type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.diversity.companyProfiles)}?${queryString}`, type)
export const getDiversityCompanyProfilesOptions = (queryString) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.diversity.companyProfilesOptions)}?${queryString}`)
export const createDiversityCompanyProfile = ({ payload, queryString }) => _requestAPI2.POST(`${analyzeAPI(endpoints.analyze.diversity.companyProfiles)}?${queryString}`, payload)
export const updateDiversityCompanyProfile = ({ id, values, queryString }) => _requestAPI2.PUT(`${analyzeAPI(endpoints.analyze.diversity.companyProfiles)}${id}/?${queryString}`, values)
export const deleteDiversityCompanyProfile = ({ id, queryString }) => _requestAPI2.DELETE(`${analyzeAPI(endpoints.analyze.diversity.companyProfiles)}${id}/?${queryString}`)

// Inclusion

// Profiles
export const getInclusionCompanyProfiles = (queryString) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.companyProfiles)}?${queryString}`)
export const getInclusionCompanyProfilesOptions = (queryString) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.companyProfilesOptions)}?${queryString}`)
export const createInclusionCompanyProfile = ({ payload, queryString }) => _requestAPI2.POST(`${analyzeAPI(endpoints.analyze.inclusion.companyProfiles)}?${queryString}`, payload)
export const updateInclusionCompanyProfile = ({ id, values, queryString }) => _requestAPI2.PUT(`${analyzeAPI(endpoints.analyze.inclusion.companyProfiles)}${id}/?${queryString}`, values)
export const deleteInclusionCompanyProfile = ({ id, queryString }) => _requestAPI2.DELETE(`${analyzeAPI(endpoints.analyze.inclusion.companyProfiles)}${id}/?${queryString}`)

export const loadDiversityData = (queryString, type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.diversity.diversityData)}?${queryString}`, type)
export const loadDiversityDataOverTime = (queryString, type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.diversity.diversityDataOverTime)}?${queryString}`, type)

// FreeTexts
export const getFreeTextsFilters = (queryString) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.freeTextsFilters)}?${queryString}`)
export const getFreeTexts = (queryString, type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.freeTexts)}?${queryString}`, type)
export const getBespokeFreeTexts = (queryString, type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.bespokeFreeTexts)}?${queryString}`, type)

// New Inclusion APIs, not used by sagas but are loaded by hooks instead
export const loadInclusionData = (queryString, type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.inclusionScore)}?${queryString}`, type)
export const loadInclusionDataOverTime = (queryString, type) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.inclusionScoreOverTime)}?${queryString}`, type)
export const getInclusionPanelData = (queryString) => _requestAPI2.GET(`${analyzeAPI(endpoints.analyze.inclusion.heatmapPanel)}?${queryString}`)

// New Programs APIs
export const getProgramFilters = () => _requestAPI2.GET(programAPI(endpoints.programs.programFilters))
export const getAllPrograms = (queryString) => _requestAPI2.GET(`${programAPI(endpoints.programs.allPrograms)}?${queryString}`)
export const saveProgram = (payload) => _requestAPI2.POST(programAPI(endpoints.programs.saveProgram), payload)
export const deleteProgram = (id) => _requestAPI2.DELETE(`${programAPI(endpoints.programs.deleteProgram)}${id}`)
export const changeProgramStatus = (payload) => _requestAPI2.POST(programAPI(endpoints.programs.changeProgramStatus), payload)

// Reporting
export const getReports = (queryString) => _requestAPI2.GET(`${reportingAPI(endpoints.reporting.reports)}?${queryString}`)
export const saveReport = (payload) => _requestAPI2.POST(reportingAPI(endpoints.reporting.saveReport), payload)
export const deleteReportFromSaved = (id) => _requestAPI2.DELETE(`${reportingAPI(endpoints.reporting.deleteReport)}${id}`)

// New Tools APIs
export const getToolsFilters = () => _requestAPI2.GET(toolsAPI(endpoints.tools.toolsFilters))
export const getAllTools = (queryString) => _requestAPI2.GET(`${toolsAPI(endpoints.tools.allTools)}?${queryString}`)
export const saveTool = (payload) => _requestAPI2.POST(toolsAPI(endpoints.tools.saveTool), payload)
export const deleteToolFromSaved = (id) => _requestAPI2.DELETE(`${toolsAPI(endpoints.tools.deleteTool)}${id}`)

// New Recommended APIs
export const loadRecommendedInsights = () => _requestAPI2.GET(recommendedAPI(endpoints.recommended.recommendedInsights))
export const loadGoalsOptions = () => _requestAPI2.GET(recommendedAPI(endpoints.recommended.goalOptions))
export const createGoal = (payload) => _requestAPI2.POST(recommendedAPI(endpoints.recommended.myGoals), payload)
export const deleteGoal = (id) => _requestAPI2.DELETE(`${recommendedAPI(endpoints.recommended.deleteGoal)}${id}`)
export const loadRecommendedPrograms = (queryString) => _requestAPI2.GET(`${recommendedAPI(endpoints.recommended.recommendedPrograms)}?${queryString}`)
export const saveRecommendedProgramV2 = (payload) => _requestAPI2.POST(recommendedAPI(endpoints.recommended.saveProgram), payload)
export const deleteRecommendedProgramV2 = (id) => _requestAPI2.DELETE(`${recommendedAPI(endpoints.recommended.deleteProgram)}${id}`)
export const changeRecommendedProgramStatus = (payload) => _requestAPI2.POST(recommendedAPI(endpoints.recommended.changeProgramStatus), payload)

// Metadata API
export const loadUserMetadata = (uuid) => _requestAPI2.GET(`${userMetadataAPI(uuid, false)}/`)
export const updateUserMetadataFeatureFlags = (uuid, payload) => _requestAPI2.PATCH(`${userMetadataAPI(uuid, false)}/feature-flag/${payload.uuid}/`, payload)

// New My Goals APIs
export const loadSolutionMyGoalsV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.myGoals))
export const loadMySavedProgramsV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.savedSolutions))
export const loadMyActiveProgramsV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.activeSolutions))
export const loadMyArchivedProgramsV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.archivedSolutions))
export const loadSolutionMyToolsV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.myTools))
export const loadSolutionMySolutionFiltersV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.myTools))
export const loadSolutionGoalsOptionsV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.goalOptions))
export const removeSavedSolutionV2 = (id) => _requestAPI2.DELETE(mySolutionsAPI(`${endpoints.goals.deleteProgram}${id}/`))
export const changeMySolutionStatus = (payload) => _requestAPI2.POST(mySolutionsAPI(`${endpoints.goals.changeProgramStatus}`), payload)
export const deleteGoalV2 = (id) => _requestAPI2.DELETE(mySolutionsAPI(`${endpoints.goals.deleteGoal}${id}/`))
export const loadSolutionGoalsInsightDataV2 = () => _requestAPI2.GET(mySolutionsAPI(endpoints.goals.insightData))
export const removeSavedToolV2 = (id) => _requestAPI2.DELETE(mySolutionsAPI(`${endpoints.goals.deleteTool}${id}/`))


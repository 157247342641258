export enum AppRoute {
    AccountMfa = '/account/mfa',
    Analyze = '/analyze2',
    AnalyzeDiversity = '/analyze2/diversity',
    AnalyzeInclusion = '/analyze2/inclusion',
    ChooseDashboard = '/choose-dashboard',
    Default = '/',
    ForgotPassword = '/forgot-password',
    Home = '/home',
    IlnDashboardHome = '/iln-dashboard/home',
    Login = '/login',
    Programs = '/programs',
    Recommended = '/recommended',
    ScheduledMaintenance = '/scheduled-maintenance',
    SendVerificationCode = '/send-verification-code',
    SolutionGoals = '/goals',
    Sso = '/sso',
    Survey = '/survey',
    Tools = '/tools',
    TwoStepVerification = '/two-step-verification',
    TwoStepVerificationBackup = '/two-step-verification-backup',
}
